import { FButton, FTypo } from "ferrum-design-system";
import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
interface Props {
  offSet: any;
  setOffSet: any;
  dataLimit: any;
  dataLength: any;
  isTable?: any;
}
export const Pagination = ({
  offSet,
  dataLength,
  dataLimit,
  setOffSet,
  isTable,
}: Props) => {
  const [paginationNumber, setPaginationNumber] = useState(0);
  // const [activeOffSet, setActiveOffSet] = useState(offSet + 1);
  console.log(offSet, dataLimit, dataLength, "124");
  return (
    <div className={"d_flex justify_end align_center f-mb-2 nextprev-m"}>
      {offSet > -1 && (
        <div
          className={" prev-m cursor_pointer"}
          onClick={() => {
            setOffSet(offSet - 9);
            setPaginationNumber(paginationNumber - 1);
          }}
        >
          {paginationNumber >= 1 && (
            <FTypo
              size={14}
              weight={400}
              color={`${isTable ? "black" : "black"}`}
              className={"f-pr-1"}
            >
              Prev
            </FTypo>
          )}
        </div>
      )}

      <div
        className={`active-page-btn f-ml-1${isTable && "bg_white clr_black"}`}
      >
        {paginationNumber}
      </div>
      {dataLength === dataLimit && (
        <div
          className={"cursor_pointer f-ml-1"}
          onClick={() => {
            setOffSet(offSet + 9);
            setPaginationNumber(paginationNumber + 1);
          }}
        >
          <FTypo
            size={14}
            weight={400}
            color={`${isTable ? "black" : "black"}`}
            className={""}
          >
            Next
          </FTypo>
        </div>
      )}
    </div>
  );
};
