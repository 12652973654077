export const defaultEndPointDetails = process.env.REACT_APP_BASE_URL;
export var smartContractAddress = "";
export var ferrumNetworkIdentifier = "";
export var gasLimit = "";
export var maxFeePerGas = "";
export var maxPriorityFeePerGas = "";
export var allowedNetwork;
export function setSmartContractAddress(address) {
  smartContractAddress = address;
}
export function setFerrumNetworkIdentifier(identifier) {
  ferrumNetworkIdentifier = identifier;
}
export function setAllowedNetwork(item) {
  allowedNetwork = item;
}

export function etherToWei(ether) {
  try {
    var Web3 = require("web3");
    return Web3.utils.toWei(String(ether), "Gwei");
  } catch (e) {
    console.log(e);
  }
}
export function gasEstimationFee(data) {
  gasLimit = data.gasLimit;
  maxFeePerGas = data.maxFeePerGas;
  maxPriorityFeePerGas = data.maxPriorityFeePerGas;
}
